<template>
  <CalenderView v-if="isShowCalender" />
  <div v-if="!isShowCalender && detailsItems" class="tab__area__wrapper">
    <label
      class="blue-text"
      @click="onClickBackCoursesButton"
      v-if="!isPersonalAccountWorkStation"
      ><v-icon icon="mdi-chevron-left"></v-icon>Back to Courses
    </label>
    <label v-else class="blue-text" @click="onClickBackCoursesButton"
      ><v-icon icon="mdi-chevron-left"></v-icon>Take me Back
    </label>
    <div class="tab__area__blocks">
      <div
        class="tab__area__left__block tw-flex tw-flex-col tw-gap-4"
        :class="{
          'tw-bg-white tw-p-8 tw-box-border tw-rounded-[8px]':
            isPersonalAccountWorkStation,
        }"
      >
        <h4 class="tab__area__title md:!tw-leading-[28px]">
          {{ detailsItems?.name }}
        </h4>
        <div
          class="tw-flex tw-items-center tw-gap-2"
          v-if="isPersonalAccountWorkStation"
        >
          <MapMarkerBlueIcon />
          <Text variant="span" textColor="#264FD5" textWeight="400"
            >In-Person & E-Learning</Text
          >
        </div>
        <div
          class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-w-full rlg:tw-flex-row rlg:tw-items-center rlg:tw-justify-between"
          v-if="isEnrollRoute || isAssignRoute"
        >
          <EnrolledChipState :statusLabel="lastCourseStatus?.label" />
          <div
            class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-gap-2 rlg:tw-gap-4 rlg:tw-items-center rlg:tw-flex-row"
          >
            <div class="tw-flex tw-items-center tw-gap-2">
              <MapMarkerYellowIcon />
              <Text variant="span" textWeight="500">{{
                selectedEnrolledCourseScheduleAddress
              }}</Text>
            </div>
            <div class="tw-flex tw-items-center tw-gap-2">
              <MapMarkerBlueIcon />
              <Text variant="span" textColor="#264FD5" textWeight="400"
                >Online & In-Person</Text
              >
            </div>
          </div>
        </div>
        <div
          class="tab__area__buttons mobile"
          v-if="
            !isPersonalAccountWorkStation && !isEnrollRoute && !isAssignRoute
          "
        >
          <!-- <v-btn class="button button-purple-border" block size="large">
            register interest for course
          </v-btn> -->
          <v-btn
            class="button button-orange !tw-mt-0"
            block
            size="large"
            @click="goToClender"
            :disabled="isSportDisable || !isEligible"
          >
            secure my spot
          </v-btn>
          <Text
            class="tw-mt-2"
            textAlign="center"
            lineHeight="18px"
            v-if="!isSportDisable && !isEligible"
            variant="p"
            textColor="red"
            >You are not eligible for this course. Please check course
            eligibility to continue.</Text
          >
        </div>
        <div class="tab__area__img !tw-mt-0">
          <img
            v-if="detailsItems?.thumbnail"
            class="align-end text-white"
            height="234"
            cover
            :src="require('@/assets/images/course/' + detailsItems?.thumbnail)"
          />
        </div>
        <div class="tab__area__content">
          <h5 class="tab__area__content__title">About</h5>
          <p class="tab__area__content__description">
            {{ detailsItems?.about }}
          </p>
        </div>
        <div class="tab__area__content">
          <h5 class="tab__area__content__title">Summary</h5>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
            <Text
              variant="p"
              v-for="(summary, index) in detailsItems?.summary"
              :key="index"
              >{{ summary.title }} -
              <Text
                variant="p"
                textWeight="400"
                textColor="rgba(12, 15, 74, 0.8)"
                >{{ summary?.description }}</Text
              ></Text
            >
          </div>
        </div>
        <div class="tab__area__content">
          <h5 class="tab__area__content__title">
            After completing this course you will be able to
          </h5>
          <ul>
            <li
              class="tab__area__content__description"
              v-for="(aim, index) in detailsItems?.aims"
              :key="index"
            >
              <v-icon icon="mdi-check-circle-outline"></v-icon> {{ aim }}
            </li>
          </ul>
        </div>
        <div class="tab__area__cards">
          <h5 class="tab__area__content__title">Course Details</h5>
          <div class="tab__area__cards__list">
            <v-card
              v-for="(courseDetail, index) in detailsItems?.courseDetails"
              :key="index"
              class="tab__area__card__item"
              :class="{ '!tw-bg-[#F3F3F6]': isPersonalAccountWorkStation }"
            >
              <div class="courses__card__prefix">
                <span class="left__prefix"
                  >{{ courseDetail?.dayCount }}
                  {{ courseDetail?.dayCount > 1 ? "Days" : "Day" }}</span
                >
                <p class="right__prefix">
                  <v-icon icon="mdi-map-marker"></v-icon>
                  {{ courseDetail?.locationType }}
                </p>
              </div>
              <div class="tab__area__cards__title">
                <img class="open-logo" src="../../../assets/images/badge.svg" />

                {{ courseDetail?.name }}
              </div>
              <p class="tab__area__cards__description">
                {{ courseDetail?.description }}
              </p>
            </v-card>
          </div>
        </div>
        <div
          class="tab__area__buttons desktop tw-flex !tw-flex-col !tw-items-center !tw-gap-2 !tw-justify-center !tw-w-full"
        >
          <!-- <v-btn class="button button-purple-border" block size="large">
            register interest for course
          </v-btn> -->
          <v-btn
            v-if="
              getUserWorkStationFieldAccess &&
              !isPersonalAccountWorkStation &&
              !isEnrollRoute &&
              !isAssignRoute
            "
            class="button button-orange !tw-w-full"
            block
            size="large"
            @click="goToClender"
            :disabled="isSportDisable || !isEligible"
          >
            secure my spot
          </v-btn>
          <Button
            label="reserve my spot"
            @click="onSelecteDate"
            v-if="isPersonalAccountWorkStation"
          ></Button>
          <Text
            textAlign="center"
            lineHeight="18px"
            v-if="!isSportDisable && !isEligible"
            variant="p"
            textColor="red"
            >You are not eligible for this course. Please check course
            eligibility to continue.</Text
          >
        </div>
      </div>
      <div
        class="tab__area__right__block"
        v-if="isEnrollRoute || isAssignRoute"
      >
        <v-card class="tab__area__right__block__card">
          <div class="enroll__content">
            <h4 class="title">You’re signed up to this course</h4>
          </div>
          <div
            v-for="status in filterCourseStatusList"
            :key="status.id"
            :class="[
              { completed: status.completed && status.isShow },
              { proj_loop: status.isShow },
            ]"
          >
            <div v-if="status.isShow">
              <div class="icon_abs">
                <v-icon icon="mdi mdi-home-circle"></v-icon>
              </div>
              <div class="proj_loop_box">
                <div class="title">{{ status.label }}</div>
                <p>
                  {{ status.info }}
                </p>
              </div>
            </div>
          </div>
          <v-btn
            v-if="isAssignRoute"
            class="download__btn button button-purple-border"
            :disabled="getSelectedCourse?.result != '1'"
            @click="onClickDownloadCertificate"
            prepend-icon="mdi-download"
            >Download Certificate</v-btn
          >
          <!-- <div
            v-if="
              getSelectedCourse.value?.enrollmentStatus === '4' &&
              getSelectedCourse.value.result
            "
          >
            <p v-if="getSelectedCourse.value.result === '1'">
              Congratulations! Download your certificate now.
            </p>
            <p v-if="getSelectedCourse.value.result === '2'">
              Don't lose hope. You can try again in the future.
            </p>
          </div> -->

          <div
            v-if="isEnrollRoute && isUserHasCourseAssignPermission"
            class="course_total"
          >
            Total:
            {{ getSelectedCourse?.selectedSpotCount }}
            Spots
            <v-btn
              variant="plain"
              height="auto"
              append-icon="mdi-chevron-right"
              class="blue-text"
              @click="toggleAssignCourseSlotToMemberModal"
              >Assign Members to Spots</v-btn
            >
          </div>
        </v-card>
      </div>
      <div
        class="tab__area__right__block"
        v-else-if="getUserWorkStationFieldAccess"
      >
        <v-card class="tab__area__right__block__card">
          <div
            class="content !tw-border-b-dashed-custom"
            :class="{
              'tw-flex tw-flex-col tw-gap-4': isPersonalAccountWorkStation,
            }"
          >
            <h4 class="title" v-if="!isPersonalAccountWorkStation">
              Sign Up To This Course Today
            </h4>
            <h6
              class="subtitle"
              :class="{
                '!tw-text-[18px] !tw-pt-0': isPersonalAccountWorkStation,
              }"
            >
              Entry requirements
            </h6>
            <p class="description tw-mb-4" v-if="!isPersonalAccountWorkStation">
              To be eligible for this course, learners must have all the
              prerequisites.
            </p>
            <div
              v-if="isPersonalAccountWorkStation"
              class="tw-flex-row-between tw-p-4 tw-border-[1px] tw-border-solid tw-border-[#4F55F0] tw-rounded-[8px] tw-bg-[rgba(79,85,240,0.1)] tw-cursor-pointer"
            >
              <Text variant="p" textWeight="500"
                >To qualify for this course, each candidate has to meet
                <Text variant="p">all the prerequisites</Text> & subscribe for a
                <Text variant="p">minimum of 3 months</Text>totaling
                <strong>£29.97</strong> + VAT, per spot.</Text
              >
            </div>
            <div
              class="tw-flex-row-between tw-px-4 tw-py-2 tw-border-[1px] tw-border-solid tw-border-[#FFA500] tw-rounded-[8px] tw-bg-[rgba(255,165,0,0.06)] tw-cursor-pointer"
              @click="toggleElegibilityModal"
            >
              <Text variant="p" textColor="#FFA500">Check eligibility</Text>
              <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
            </div>
          </div>
          <div class="tab__area__list">
            <h6 class="title">Summary</h6>
            <ul>
              <!-- <li>
                <div class="tab__area__list__icon">
                  <img
                    class="open-logo"
                    src="../../../assets/icons/duration-filled-icon.svg"
                  />
                </div>
                <p>Duration 10 days</p>
              </li> -->
              <li>
                <div class="tab__area__list__icon">
                  <img
                    class="open-logo"
                    src="../../../assets/icons/mic-filled-icon.svg"
                  />
                </div>
                <p>English</p>
              </li>
              <li>
                <div class="tab__area__list__icon">
                  <img
                    class="open-logo"
                    src="../../../assets/icons/yellow-building-filled-icon.svg"
                  />
                </div>
                <p>In Person & E-Learning Lessons</p>
              </li>
              <li>
                <div class="tab__area__list__icon">
                  <img
                    class="open-logo"
                    src="../../../assets/icons/yellow-location-icon.svg"
                  />
                </div>
                <p>Locations Nationwide</p>
              </li>
              <li>
                <div class="tab__area__list__icon">
                  <img
                    class="open-logo"
                    src="../../../assets/icons/yellow-certificate-icon.svg"
                  />
                </div>
                <p>Certification on completion</p>
              </li>
            </ul>
          </div>
          <div class="tab__area__price">
            <ul>
              <li>
                <div class="tab__area__item tw-flex tw-flex-col tw-items-start">
                  <Text variant="p" lineHeight="18px"
                    >Sole Trader/ LTD Sole Director</Text
                  >
                  <Text
                    variant="xsmall"
                    textColor="rgba(12, 15, 74, 0.5)"
                    lineHeight="18px"
                    >(100% Subsidy)</Text
                  >
                </div>
                <div class="tw-flex tw-flex-col tw-items-end tw-gap-0">
                  <Text
                    class="tw-line-through"
                    variant="p"
                    textColor="#7E82A2"
                    textWeight="600"
                    lineHeight="18px"
                    >£{{
                      detailsItems?.title ===
                      "Solar PV, Battery Storage & EV Charging Level 3"
                        ? "1,837"
                        : "1,558"
                    }}</Text
                  >
                  <Text variant="p" textColor="#FFA500" lineHeight="18px"
                    >FREE</Text
                  >
                </div>
              </li>
              <li>
                <div class="tab__area__item tw-flex tw-flex-col tw-items-start">
                  <Text variant="p" lineHeight="18px">Limited Company</Text>
                  <Text
                    variant="xsmall"
                    textColor="rgba(12, 15, 74, 0.5)"
                    lineHeight="18px"
                    >(90% Subsidy)</Text
                  >
                </div>
                <div class="tw-flex tw-flex-col tw-items-end tw-gap-0">
                  <Text
                    class="tw-line-through"
                    variant="p"
                    textColor="#7E82A2"
                    textWeight="600"
                    lineHeight="18px"
                    >From</Text
                  >
                  <Text variant="p" textColor="#FFA500" lineHeight="18px"
                    >£155.80</Text
                  >
                </div>
              </li>
            </ul>
          </div>

          <v-checkbox
            v-if="!isPersonalAccountWorkStation"
            v-model="tcOptIn"
            class="tab__area__checkbox"
          >
            <template v-slot:label>
              <p>
                <span class="blue-text remove-padding">
                  I have read and agree with the
                </span>
                <span @click="openTermsServiceModal" class="yellow-text">
                  Terms of Service & Privacy Policy</span
                >
                <!-- <span class="">Select checkbox to continue</span> -->
              </p>
            </template>
          </v-checkbox>

          <v-checkbox
            v-if="!isPersonalAccountWorkStation"
            v-model="marketingOptIn"
            class="tab__area__checkbox remove-padding"
          >
            <template v-slot:label>
              <p>
                <span class="blue-text remove-padding">
                  I have read and agree with the
                </span>
                <span class="yellow-text">Cancellation Policy</span>
              </p>
            </template>
          </v-checkbox>
          <!-- <span class="">Select checkbox to continue</span> -->

          <!-- <div class="courses__card__users">
            <ul class="courses__card__users__img">
              <li>
                <img src="../../../assets/images/sample-avatar.jpg" alt="" />
              </li>
              <li>
                <img src="../../../assets/images/sample-avatar.jpg" alt="" />
              </li>
              <li>
                <img src="../../../assets/images/sample-avatar.jpg" alt="" />
              </li>
              <li>
                <img src="../../../assets/images/sample-avatar.jpg" alt="" />
              </li>
              <li>
                <img src="../../../assets/images/sample-avatar.jpg" alt="" />
              </li>
            </ul>
            <p>+11 More Enrolled</p>
          </div> -->
          <v-btn
            v-if="!isPersonalAccountWorkStation"
            class="button button-orange"
            size="large"
            block
            @click="goToClender"
            :disabled="isSportDisable || !isEligible"
          >
            secure my spot
          </v-btn>
          <Button
            label="reserve my spot"
            class="!tw-mt-8"
            @click="onSelecteDate"
            v-else
          ></Button>
          <div
            class="tw-w-full tw-mt-2 tw-flex tw-items-center tw-justify-center"
          >
            <Text
              variant="span"
              textColor="rgba(12, 15, 74, 0.6)"
              textAlign="center"
              >Terms & Conditions Apply</Text
            >
          </div>

          <Text
            class="tw-mt-2"
            textAlign="center"
            lineHeight="18px"
            v-if="!isSportDisable && !isEligible"
            variant="p"
            textColor="red"
            >You are not eligible for this course. Please check course
            eligibility to continue.</Text
          >
          <!-- <v-btn class="button button-purple-border" size="large" block>
            register interest for course
          </v-btn> -->
        </v-card>
      </div>
    </div>

    <!-- MODALS -->
    <CheckElegibilityModal
      v-if="eligibilityModal"
      @on-close="toggleElegibilityModal"
    />
    <EntryRequirementsModal
      v-if="entryRequirementsModal"
      @on-back="toggleElegibilityModal"
      @on-reserve-spot="openReserveSpotModal"
    />
    <CoursesTermsConditionModal
      v-if="termsServiceModal"
      @closeTermsAndConditionModal="closeTermsAndConditionModal"
    />
    <PageRestrictionNoticeModal
      v-if="isShowWorkstationRestrictionModal"
      @on-close="toggleRestrictionModal"
    />

    <ReserveSpotsModal
      v-if="isShowReserveSpotModal"
      @on-close="closeReserveSpotModal"
      @on-continue="onOpenTermsModal"
    />

    <CoursePreEnrollTermsConditionsModal
      v-if="isShowCourseTermsModal"
      @on-reserve-spot="onReserveSpot"
      @on-back="openReserveSpotModal"
      @on-check-elegibility="toggleElegibilityModal"
      :loading="isReserving"
    />

    <SuccessReserveSpotModal
      v-if="isShowSuccessReserveSpotModal && isPersonalAccountWorkStation"
      @on-close="onCloseSuccessReserveSpotModal"
      @on-select-ws="openSelectWsModalModal"
    />

    <SelectWorkstationModal
      v-if="isShowSelectWsModal"
      @on-back="onOpenSuccessReserveSpotModal"
    />
    <AssignCourseSlotToMemberModal
      isfromCourseModule
      :selectedUserCourseIdForAssign="selectedUserCourseIdForAssignMember"
      @onClickCloseButton="toggleAssignCourseSlotToMemberModal"
      v-if="isOpenAssignCourseSlotToMemberModal"
    />
  </div>
</template>

<script lang="ts">
import { ref, onBeforeMount, computed, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { COURSES_STORE } from "@/store/modules/courses";
import { useRouter, useRoute } from "vue-router";
import CalenderView from "@/modules/courses/components/CalenderView.vue";
import CoursesTermsConditionModal from "@/modules/courses/components/CoursesTermsConditionModal.vue";
import Text from "../../../core/components/ui/general/Text.vue";
import EnrolledChipState from "./EnrolledChipState.vue";
import CheckElegibilityModal from "./CheckElegibilityModal.vue";
import MapMarkerYellowIcon from "@/core/components/icons/MapMarkerYellowIcon.vue";
import MapMarkerBlueIcon from "@/core/components/icons/MapMarkerBlueIcon.vue";
import { airSourceHeatPumpData, greenSkillsData } from "../data/courseDetails";
import { stringify } from "postcss";
import { getImageApiUrl } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import moment from "moment";
import { WORKSTATION } from "@/store/modules/workstation";
import PageRestrictionNoticeModal from "@/core/components/modals/PageRestrictionNoticeModal.vue";
import EntryRequirementsModal from "@/modules/courses/components/EntryRequirementsModal.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import ReserveSpotsModal from "@/modules/courses/components/ReserveSpotsModal.vue";
import CoursePreEnrollTermsConditionsModal from "@/modules/courses/components/CoursePreEnrollTermsConditionsModal.vue";
import SuccessReserveSpotModal from "@/modules/courses/components/SuccessReserveSpotModal.vue";
import SelectWorkstationModal from "@/modules/courses/components/SelectWorkstationModal.vue";
import { USER_STORE } from "@/store/modules/user";
import AssignCourseSlotToMemberModal from "@/modules/dashboard/components/Modals/AssignCourseSlotToMemberModal.vue";
import { COURSE_ROUTE } from "../routes";
import { UserWorkstationMemberRoleEnum } from "@/core/enums/RolesEnum";

export default {
  name: "CoursesDetailsView",
  components: {
    CalenderView,
    CheckElegibilityModal,
    Text,
    EnrolledChipState,
    MapMarkerYellowIcon,
    MapMarkerBlueIcon,
    CoursesTermsConditionModal,
    PageRestrictionNoticeModal,
    EntryRequirementsModal,
    Button,
    ReserveSpotsModal,
    CoursePreEnrollTermsConditionsModal,
    SuccessReserveSpotModal,
    SelectWorkstationModal,
    AssignCourseSlotToMemberModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route: any = useRoute();
    const tcOptIn = ref(false);
    const marketingOptIn = ref(false);
    const isShowCalender = ref(false);
    const detailsItems = ref(null) as any;
    const courseEnrollmentStatus = ref(null) as any;
    const variableName = ref();
    const loading = ref(false);
    const termsServiceModal = ref(false);
    const eligibilityModal = ref(false);
    const entryRequirementsModal = ref(false);
    const eligibility = ref(null) as any;
    const selectedEnrolledCourseScheduleAddress = ref(null) as any;
    const isShowReserveSpotModal = ref(false);
    const isShowCourseTermsModal = ref(false);
    const isShowSuccessReserveSpotModal = ref(false);
    const isShowSelectWsModal = ref(false);
    const isReserving = ref(false);
    const selectedUserCourseIdForAssignMember = Number(
      route.params?.selectedEnrollCourse
    );
    variableName.value = router.currentRoute?.value?.params?.variableName;
    const courseStatusItems = [
      {
        id: 1,
        label: "Spot Reserved",
        info: " Your pre-enrollment is confirmed. Stay tuned for further updates.",
        completed: false,
        isShow: true,
      },
      {
        id: 2,
        label: "Enrolled",
        info: "Congratulations! You are successfully enrolled in the program.",
        completed: false,
        isShow: true,
      },
      {
        id: 3,
        label: "Rejected",
        info: "We regret to inform you that your application has been rejected.",
        completed: false,
        isShow: true,
      },
      {
        id: 4,
        label: "Waitlist",
        info: "You are on the course waitlist.",
        completed: false,
        isShow: true,
      },
      {
        id: 5,
        label: "Awaiting Results",
        info: "Your application is under review. Please await the results.",
        completed: false,
        isShow: true,
      },
      {
        id: 6,
        label: "Results Awarded",
        info: "",
        completed: false,
        isShow: true,
      },
    ];

    const openSelectWsModalModal = () => {
      onCloseSuccessReserveSpotModal();
      isShowSelectWsModal.value = true;
    };

    const onCloseSelectWsModalModal = () => {
      isShowSelectWsModal.value = false;
    };

    const closeTermsAndConditionModal = () => {
      termsServiceModal.value = false;
    };

    const openTermsServiceModal = () => {
      termsServiceModal.value = true;
    };

    const closeReserveSpotModal = () => {
      console.log("runnneeddd hereee");
      isShowReserveSpotModal.value = false;
    };

    const openReserveSpotModal = () => {
      onCloseTermsModal();
      isShowReserveSpotModal.value = true;
    };

    const onCloseTermsModal = () => {
      console.log("close termsss modalll");
      isShowCourseTermsModal.value = false;
    };

    const onOpenTermsModal = () => {
      closeReserveSpotModal();
      isShowCourseTermsModal.value = true;
    };

    const onCloseSuccessReserveSpotModal = () => {
      isShowSuccessReserveSpotModal.value = false;
    };

    const onOpenSuccessReserveSpotModal = () => {
      onCloseTermsModal();
      onCloseSelectWsModalModal();
      isShowSuccessReserveSpotModal.value = true;
    };

    const isSportDisable = computed(() => {
      return !(marketingOptIn.value && tcOptIn.value);
    });

    const isEligible = computed(() => {
      if (eligibility.value) {
        return eligibility.value.isEligible;
      }
      return false;
    });

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const selectedDates = computed(
      () => store.getters[`${COURSES_STORE}/selectedDates`]
    );

    const reservedSpotsData = computed(
      () => store.getters[`${COURSES_STORE}/reservedSpotsData`]
    );

    const getCheckElegibility = async () => {
      const courseId = route?.params?.courseId;
      try {
        let response = await store.dispatch(
          `${COURSES_STORE}/checkElegibility`,
          courseId
        );
        console.log("getCheckElegibility", response);

        if (response.data) {
          eligibility.value = response;
        }
      } catch (error) {
        console.log(error);
      }
    };

    // transfer this to terms modal
    const onReserveSpot = async () => {
      isReserving.value = true;
      const courseId = Array.isArray(route?.params?.courseId)
        ? route.params.courseId[0]
        : route?.params?.courseId;
      // gather required data
      const userId = user.value.id;
      const courseReserveData = selectedDates.value.map((date: any) => {
        return {
          courseId: parseInt(courseId, 10),
          courseScheduleId: date.id,
          selectedSpotCount: Number(date.spots),
        };
      });

      const request = {
        userId,
        courseReserveData,
      };

      // reserve spot
      try {
        const response = await store.dispatch(
          `${COURSES_STORE}/reserveSpot`,
          request
        );
        if (response && response.length) {
          onOpenSuccessReserveSpotModal();
        }
      } catch (error) {
        console.log(error);
      } finally {
        isReserving.value = false;
      }
    };

    const getSelectedCourse = computed(
      () => store.getters[`${COURSES_STORE}/courseDetails`]
    );
    const filterCourseStatusList = computed(() => {
      const courseStatusList = JSON.parse(JSON.stringify(courseStatusItems));
      if (getSelectedCourse.value?.result === "1") {
        courseStatusList[0].completed = true;
        courseStatusList[1].completed = true;
        courseStatusList[5].completed = true;
        courseStatusList[5].info =
          " Congratulations! Download your certificate now.";

        courseStatusList[2].isShow = false;
        courseStatusList[3].isShow = false;
        courseStatusList[4].isShow = false;
      } else if (getSelectedCourse.value?.result === "2") {
        courseStatusList[0].completed = true;
        courseStatusList[1].completed = true;
        courseStatusList[5].completed = true;
        courseStatusList[5].info =
          " Don't lose hope. You can try again in the future.";

        courseStatusList[2].isShow = false;
        courseStatusList[3].isShow = false;
        courseStatusList[4].isShow = false;
      } else if (getSelectedCourse.value?.enrollmentStatus === "1") {
        courseStatusList[0].completed = true;
        courseStatusList[1].completed = true;
        courseStatusList[2].isShow = false;
        courseStatusList[3].isShow = false;
      } else if (getSelectedCourse.value?.enrollmentStatus === "2") {
        courseStatusList[0].completed = true;
        courseStatusList[1].completed = true;
        courseStatusList[2].isShow = false;
        courseStatusList[3].isShow = false;
      } else if (getSelectedCourse.value?.enrollmentStatus === "3") {
        courseStatusList[0].completed = true;
        courseStatusList[1].completed = true;
        courseStatusList[2].completed = true;
        courseStatusList[3].isShow = false;
        courseStatusList[4].isShow = false;
        courseStatusList[5].isShow = false;
      } else if (getSelectedCourse.value?.enrollmentStatus === "4") {
        courseStatusList[0].completed = true;
        courseStatusList[1].completed = true;
        courseStatusList[3].completed = true;
        courseStatusList[2].isShow = false;
        courseStatusList[4].isShow = false;
        courseStatusList[5].isShow = false;
      }

      if (
        getSelectedCourse.value?.userCourseSchedules?.length &&
        moment(
          getSelectedCourse.value?.userCourseSchedules[0]?.courseSchedule
            ?.startDate
        ).isBefore(moment()) &&
        !["1", "3", "4"].includes(getSelectedCourse.value?.enrollmentStatus)
      ) {
        courseStatusList[4].completed = true;
      }
      if (isEnrollRoute.value)
        return [courseStatusList[0], courseStatusList[1]];
      return courseStatusList;
    });
    const lastCourseStatus = computed(() =>
      filterCourseStatusList.value.findLast((course: any) => course.completed)
    );
    const onClickDownloadCertificate = async () => {
      try {
        const fileURLString = getSelectedCourse.value.certificateAttachment;
        if (fileURLString) window.open(fileURLString);
      } catch (error) {
        console.log("error", error);
      }
    };

    const isEnrollRoute = computed(() => {
      return route?.params?.variableName === "enroll";
    });
    const isAssignRoute = computed(() => {
      return ["assign", "completed"].includes(route?.params?.variableName);
    });

    const goToClender = () => {
      if (isEligible.value) {
        isShowCalender.value = true;
      }
      return;
    };

    // const onOpenEntryRequirementsModal = () => {
    //   entryRequirementsModal.value = true;
    // };

    // const onCloseEntryRequirementsModal = () => {
    //   entryRequirementsModal.value = false;
    // };

    const toggleElegibilityModal = () => {
      if (isPersonalAccountWorkStation.value) {
        entryRequirementsModal.value = !entryRequirementsModal.value;
        return;
      }
      eligibilityModal.value = !eligibilityModal.value;
    };

    const getCourseData = async () => {
      let coursesWithExtraInfo = [] as any;
      const selectedCourseId = route.params?.courseId;
      const selectedEnrollCourseId = route.params?.selectedEnrollCourse;
      try {
        loading.value = true;
        // if user did refresh the page it will call the getCourses endpoint else it will retain the stored datavalue);
        if (!getSelectedCourse.value) {
          // onClickBackCoursesButton();
          let response = await store.dispatch(`${COURSES_STORE}/getCourses`);
          if (response?.data) {
            if (
              !response.data.find(
                (course: any) => course.id == selectedCourseId
              )
            )
              router.push({ name: COURSE_ROUTE });
            coursesWithExtraInfo = response.data.map((course: any) => {
              if (course.id === 1) {
                return {
                  ...course,
                  ...airSourceHeatPumpData,
                };
              }
              if (course.id === 2) {
                return {
                  ...course,
                  ...greenSkillsData,
                };
              }
            });

            if (coursesWithExtraInfo.length) {
              detailsItems.value = coursesWithExtraInfo.find(
                (course: any) => course.id.toString() === selectedCourseId
              );
              if (
                detailsItems.value?.userCourses?.length &&
                selectedEnrollCourseId
              ) {
                let response = await store.dispatch(
                  `${COURSES_STORE}/getEnrollCourses`,
                  {
                    userId: user.value.id,
                  }
                );

                let selectedEnrollCourse = response.data?.find(
                  (item: any) => item?.id == selectedEnrollCourseId
                );
                if (selectedEnrollCourse?.userCourseSchedules[0]?.id) {
                  if (selectedEnrollCourse.course.id === 1) {
                    selectedEnrollCourse = {
                      ...selectedEnrollCourse,
                      course: {
                        ...selectedEnrollCourse.course,
                        ...airSourceHeatPumpData,
                      },
                      courseMode: "In-Person & E-Learning",
                    };
                  }
                  if (selectedEnrollCourse.course.id === 2) {
                    selectedEnrollCourse = {
                      ...selectedEnrollCourse,
                      course: {
                        ...selectedEnrollCourse.course,
                        ...greenSkillsData,
                      },
                      courseMode: "In-Person & E-Learning",
                    };
                  }
                } else {
                  selectedEnrollCourse = null;
                }
                if (selectedEnrollCourse && selectedEnrollCourseId) {
                  await store.dispatch(
                    `${COURSES_STORE}/saveCourseDetails`,
                    selectedEnrollCourse
                  );
                } else {
                  router.push({ name: COURSE_ROUTE });
                }
              } else if (detailsItems.value?.userCourses?.length) {
                await store.dispatch(
                  `${COURSES_STORE}/saveCourseDetails`,
                  detailsItems.value?.userCourses[0]
                );
              }
            }
          }
        } else {
          if (!isEnrollRoute.value) {
            detailsItems.value = getSelectedCourse.value;
          } else {
            detailsItems.value = getSelectedCourse.value.course;
            courseEnrollmentStatus.value = getSelectedCourse.value;
            selectedEnrolledCourseScheduleAddress.value =
              courseEnrollmentStatus.value?.userCourseSchedules[0]?.courseSchedule.address;
          }
        }
      } catch (error) {
        console.log("error:", error);
      } finally {
        loading.value = false;
      }
    };
    const setAssignCourseData = async () => {
      try {
        const selectedCourseId = route.params?.courseId;
        const selectedAssignCourseId = route.params?.selectedEnrollCourse;
        loading.value = true;
        if (!getSelectedCourse.value) {
          const response = await store.dispatch(
            `${COURSES_STORE}/getAssignedCourses`,
            {
              userId: user.value.id,
            }
          );
          const currentSelectedCourseDetails = response.data.find(
            (item: any) =>
              item.id == selectedAssignCourseId &&
              item?.userCourse?.course?.id == selectedCourseId
          );

          if (!currentSelectedCourseDetails)
            router.push({ name: COURSE_ROUTE });
          const finalCourseDetails = currentSelectedCourseDetails
            ? currentSelectedCourseDetails?.userCourse?.course?.id == 1
              ? {
                  ...currentSelectedCourseDetails,
                  course: {
                    ...currentSelectedCourseDetails?.userCourse?.course,
                    ...airSourceHeatPumpData,
                  },
                  userCourseSchedules:
                    currentSelectedCourseDetails?.userCourse
                      ?.userCourseSchedules,
                  courseMode: "In-Person & E-Learning",
                }
              : currentSelectedCourseDetails?.userCourse?.course?.id == 2
              ? {
                  ...currentSelectedCourseDetails,
                  course: {
                    ...currentSelectedCourseDetails?.userCourse?.course,
                    ...greenSkillsData,
                  },
                  userCourseSchedules:
                    currentSelectedCourseDetails?.userCourse
                      ?.userCourseSchedules,
                  courseMode: "In-Person & E-Learning",
                }
              : null
            : null;
          await store.dispatch(
            `${COURSES_STORE}/saveCourseDetails`,
            finalCourseDetails
          );

          console.log("finalCourseDetails :>> ", finalCourseDetails);
          courseEnrollmentStatus.value = finalCourseDetails;
          detailsItems.value = finalCourseDetails?.course;
          selectedEnrolledCourseScheduleAddress.value =
            courseEnrollmentStatus.value?.userCourseSchedules[0]?.courseSchedule.address;
        } else {
          courseEnrollmentStatus.value = getSelectedCourse.value;
          detailsItems.value = getSelectedCourse.value.course;
          selectedEnrolledCourseScheduleAddress.value =
            courseEnrollmentStatus.value?.userCourseSchedules[0]?.courseSchedule.address;
        }
      } catch (error) {
        console.log("set assign course details error:", error);
      } finally {
        loading.value = false;
      }
    };
    const getUserWorkStationFieldAccess = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
    );

    const isShowWorkstationRestrictionModal = ref(false);
    const isPersonalAccountWorkStation = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]?.name ===
        "Default"
    );

    const filteredReserveSpots = computed(() => {
      const courseId = route?.params?.courseId;

      if (reservedSpotsData.value && reservedSpotsData.value.length) {
        // Filter spots by courseId and and no subscription
        return reservedSpotsData.value.filter((spot: any) => {
          return spot.course?.id == courseId && !spot.subscription;
        });
      }

      return [];
    });

    const onClickBackCoursesButton = () => {
      if (!isPersonalAccountWorkStation.value) {
        router.push("/courses");
      } else {
        router.go(-1);
      }
    };

    const toggleRestrictionModal = () => {
      isShowWorkstationRestrictionModal.value =
        !isShowWorkstationRestrictionModal.value;
    };

    const onSelecteDate = () => {
      openReserveSpotModal();
    };

    onBeforeMount(async () => {
      if (isAssignRoute.value) {
        setAssignCourseData();
      } else {
        getCourseData();
      }
      console.log(reservedSpotsData.value, "reservedSpotsData");
      console.log(filteredReserveSpots.value, "triggered");
      if (filteredReserveSpots.value && filteredReserveSpots.value.length) {
        console.log("triggered open timer");
        onOpenSuccessReserveSpotModal();
      }
      if (!isEnrollRoute.value && !isAssignRoute.value) {
        getCheckElegibility();
      }
    });

    onBeforeUnmount(() => {
      detailsItems.value = null;
      eligibility.value = null;
    });

    const isOpenAssignCourseSlotToMemberModal = ref(false);

    const toggleAssignCourseSlotToMemberModal = (userCourseId = null) => {
      isOpenAssignCourseSlotToMemberModal.value =
        !isOpenAssignCourseSlotToMemberModal.value;
    };

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const isUserHasCourseAssignPermission = computed(() => {
      const isUserCreatorOfWS =
        user.value?.id === activeUserWorkstation.value?.user?.id;

      const isUserHasEditPermission = [
        UserWorkstationMemberRoleEnum.ADMIN,
        UserWorkstationMemberRoleEnum.EDITOR,
      ].includes(activeUserWorkstation.value?.role);

      if (isUserCreatorOfWS) return true;
      return isUserHasEditPermission;
    });

    return {
      onClickBackCoursesButton,
      detailsItems,
      tcOptIn,
      marketingOptIn,
      isShowCalender,
      goToClender,
      isSportDisable,
      variableName,
      eligibilityModal,
      toggleElegibilityModal,
      isEligible,
      isEnrollRoute,
      selectedEnrolledCourseScheduleAddress,
      onClickDownloadCertificate,
      filterCourseStatusList,
      getSelectedCourse,
      lastCourseStatus,
      termsServiceModal,
      closeTermsAndConditionModal,
      openTermsServiceModal,
      getUserWorkStationFieldAccess,
      toggleRestrictionModal,
      isPersonalAccountWorkStation,
      isShowWorkstationRestrictionModal,

      entryRequirementsModal,
      onSelecteDate,
      openReserveSpotModal,
      closeReserveSpotModal,
      isShowReserveSpotModal,
      isShowCourseTermsModal,
      onOpenTermsModal,
      onCloseTermsModal,
      isShowSuccessReserveSpotModal,
      onOpenSuccessReserveSpotModal,
      onCloseSuccessReserveSpotModal,
      isShowSelectWsModal,
      onCloseSelectWsModalModal,
      openSelectWsModalModal,
      onReserveSpot,
      isReserving,
      toggleAssignCourseSlotToMemberModal,
      isOpenAssignCourseSlotToMemberModal,
      selectedUserCourseIdForAssignMember,
      isAssignRoute,
      isUserHasCourseAssignPermission,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/courses/styles/courses.scss";
.completed {
  border-left: 1px dashed #faa500 !important;

  .icon_abs i,
  .proj_loop_box .title,
  .proj_loop_box p {
    color: #faa500 !important;
  }
}

.download__btn {
  display: flex;
  width: 100%;
}
.course_total {
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: rgba($blueDark, 0.06);
  border-radius: 6px;
  @include fluidFont(14, 14, 1.3);
  font-weight: 500;
  color: rgba($blueDark, 0.5);
  padding: 10px;
  .blue-text {
    padding: 0;
    text-transform: capitalize;
    color: rgba($PrimaryPurple, 1);
    background-color: transparent;
    opacity: 1;
  }
}
</style>
